import { useForm } from "react-hook-form";
import React from "react";
import Button from './button'
import API from '@aws-amplify/api';
import {
    FormLabel,
    FormControl,
    Spinner,
    Input,
    Container,
    Box,
    Text,
    Textarea
} from "@chakra-ui/react";

export default function HookForm() {
    const { handleSubmit, errors, clearErrors, setError, register, formState } = useForm();

    function removeError() {
        setInterval(
            function () { clearErrors(); },
            5000
        );
    }
    function onSubmit(values) {
        var data = {
            body: values,
            headers: {
                "Content-Type": "application/json"
            }
        };
        console.log("VALUES", data)
        setError("submitting", { status: true });
        API
            .post('api', '/email/send', data)
            .then(response => {
                console.log("API RESPONSE", response)
                setError("submitting", { status: false });

                setError("message", {
                    type: "submit",
                    message: "Thanks - We will be in touch"
                });
                removeError()

            })
            .catch(error => {
                setError("submitting", { status: false });
                console.log("API ERROR", error)
                setError("submitting", false);
                setError("message", {
                    type: "submit",
                    message: "Failed to submit message."
                });
                removeError()
            })
    }

    return (
        <Container width={["100%", "100%", "80%", "80%"]}>
            {/* <Center height="100vh" width="100%"> */}
            <Box width={"100%"} as="form" onSubmit={handleSubmit(onSubmit)}>
                <FormControl isInvalid={errors.name}>
                    <FormLabel marginTop={3} htmlFor="name"><Text variant="Label">First name</Text></FormLabel>
                    <Input marginBottom={0}
                        borderColor="brandYellow.50"
                        name="firstname"
                        placeholder="Firstname"
                        ref={register({ required: true })}
                    // ref={register({ validate: validateName })}
                    />
                    {errors.firstname && <Container variant="Error">Firstname Required</Container>}
                    <FormLabel marginTop={3} htmlFor="surname"><Text variant="Label">Surname</Text></FormLabel>
                    <Input
                        borderColor="brandYellow.50"
                        marginBottom={0}
                        name="surname"
                        placeholder="Surname"
                        ref={register({ required: true })}
                    />
                    {errors.surname && <Container variant="Error">Surname Required</Container>}
                    <FormLabel marginTop={3} htmlFor="email"><Text variant="Label">eMail Address</Text></FormLabel>
                    <Input
                        borderColor="brandYellow.50"
                        marginBottom={0}
                        name="email"
                        placeholder="eMail Address"
                        ref={register({
                            required: "Required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address"
                            }
                        })}
                    />
                    {errors.email &&
                        <Container variant="Error">Invalid eMail address</Container>
                    }
                    <FormLabel marginTop={3} htmlFor="phoneNumber"><Text variant="Label">Phone Number</Text></FormLabel>
                    <Input
                        borderColor="brandYellow.50"
                        marginBottom={0}
                        name="phoneNumber"
                        placeholder="Phone Number"
                        ref={register({ required: false })}
                    />
                        <Box>
                        <FormLabel marginTop={3} htmlFor="content"><Text variant="Label">Message</Text></FormLabel>
                        <Textarea
                            borderColor="brandYellow.50"
                            marginBottom={0}
                            name="content"
                            placeholder="Message"
                            ref={register({ required: true })}
                        />
                        </Box>
                    {errors.content && <Container variant="Error">Message Required</Container>}
                    {errors.message &&
                        <Container variant="Error">{errors.message.message}</Container>
                    }

                </FormControl>
                {errors.submitting && errors.submitting.status ?
                    <Container>    <Spinner color="brandWhite.500" />
                    </Container>
                    :
                    <Button buttonText="Submit" mt={10} isLoading={formState.isSubmitting} type="submit">
                    </Button>
                }



            </Box>
            {/* </Center> */}
        </Container>
    );
}